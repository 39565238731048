import * as React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
// import "./Product.css";
import styles from "./Product.module.css";
// import "./ProductsImages/3jpg";

// import Imgslider from "./Imgslider/Imgslider";

const Product = ({ products }) => {
  const DimThick = ({ DT }) => {
    const [dim, setDim] = useState(null);
    const [thicknessState, setThicknessState] = useState(DT.Thickness[0]);

    const handleDimensions = (e) => {
      let currentDimention = e.target.value;
      let i = DT.Dimension.indexOf(currentDimention);
      setThicknessState(DT.Thickness[i]);
    };
    if (DT.Dimension.length > 1) {
      return (
        <>
          <li>
            <strong>Dimension/Size: </strong>
            <span>
              <select id={styles.Dimensions} onChange={handleDimensions}>
                {DT.Dimension.map((dim, i) => (
                  <option value={dim} key={i}>
                    {dim}
                  </option>
                ))}
              </select>
            </span>
          </li>
          <li>
            <strong>Thickness: </strong>
            <span>{thicknessState}</span>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li>
            <strong>Dimension/Size: </strong>
            <span>{DT.Dimension[0]}</span>
          </li>
          <li>
            <strong>Thickness: </strong>
            <span>{thicknessState}</span>
          </li>
        </>
      );
    }
  };

  return (
    <>
      {products.map((product, i) => (
        <div id={styles.pcontainer} key={i}>
          {/* <!-- Start	Product details --> */}
          <div className={styles.productdetails}>
            <h2>{product.name}</h2>
            <ul>
              <li>
                <strong>Design: </strong>
                <span>{product.Design}</span>
              </li>
              <li>
                <strong>Usage: </strong>
                <span>{product.Usage}</span>
              </li>
              <li>
                <strong>Color: </strong>
                <span>{product.Color}</span>
              </li>
              <DimThick DT={product} />
              <li>
                <Button
                  variant="outline-success"
                  style={{
                    display: "flex",
                    alignContent: "center",
                    flexWrap: "nowrap",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-around"
                  }}
                  onClick={() => {
                    window.location.href = "tel:+917902580078";
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-telephone"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg>
                  Make A Call To Order
                </Button>
              </li>
            </ul>
          </div>

          {/* <!-- 	End	Product details   --> */}

          {/* Start product image & Information */}

          <div className={styles.productimage}>
            <img src={product.src} alt="Omar Dsoky" />

            <div className={styles.info}>
              {/* <!-- 	Product Name --> */}
              <h1>{product.name}</h1>

              {/* <!-- The most important information about the product --> */}
              <p className={styles.information}>
                " The manufacturer of a wide assortment of Cotton Cloth Bag,
                Paper Bag, Grocerry Bag and many more. These products are known
                for their matchless quality and remarkable finish."
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Product;
